var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "c-app flex-row align-items-center login",
      class: { "c-dark-theme": _vm.$store.state.darkMode }
    },
    [
      _c(
        "CContainer",
        [
          _c(
            "CRow",
            { staticClass: "justify-content-center" },
            [
              _c("CCol", { staticClass: "login-logo", attrs: { md: "6" } }, [
                _c("img", { attrs: { src: "img/jogg-logo-white.svg" } }),
                _c("br"),
                _vm._v(" Management Portal ")
              ])
            ],
            1
          ),
          _c(
            "CRow",
            { staticClass: "justify-content-center" },
            [
              _c(
                "CCol",
                { attrs: { md: "6" } },
                [
                  _c(
                    "CCardGroup",
                    [
                      _c(
                        "CCard",
                        { staticClass: "p-4" },
                        [
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CForm",
                                [
                                  _c("h1", [_vm._v("Login")]),
                                  _c("CInput", {
                                    attrs: {
                                      placeholder: "Phone",
                                      autocomplete: "phone"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend-content",
                                        fn: function() {
                                          return [
                                            _c("CIcon", {
                                              attrs: { name: "cil-phone" }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ]),
                                    model: {
                                      value: _vm.email,
                                      callback: function($$v) {
                                        _vm.email = $$v
                                      },
                                      expression: "email"
                                    }
                                  }),
                                  _c("CInput", {
                                    attrs: {
                                      placeholder: "Password",
                                      type: "password",
                                      autocomplete: "curent-password"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "prepend-content",
                                        fn: function() {
                                          return [
                                            _c("CIcon", {
                                              attrs: { name: "cil-lock-locked" }
                                            })
                                          ]
                                        },
                                        proxy: true
                                      }
                                    ]),
                                    model: {
                                      value: _vm.password,
                                      callback: function($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password"
                                    }
                                  }),
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        {
                                          staticClass: "text-left",
                                          attrs: { col: "6" }
                                        },
                                        [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "px-0",
                                              attrs: { color: "link" },
                                              on: {
                                                click: _vm.onForgotPasswordClick
                                              }
                                            },
                                            [_vm._v("Forgot password?")]
                                          ),
                                          _c(
                                            "CModal",
                                            {
                                              attrs: {
                                                title: "Forgot password",
                                                show: _vm.forgotPasswordModal,
                                                centered: "",
                                                closeOnBackdrop: false
                                              },
                                              on: {
                                                "update:show": function(
                                                  $event
                                                ) {
                                                  _vm.forgotPasswordModal = $event
                                                }
                                              },
                                              scopedSlots: _vm._u([
                                                {
                                                  key: "footer",
                                                  fn: function() {
                                                    return [
                                                      _c(
                                                        "CButton",
                                                        {
                                                          staticClass: "px-4",
                                                          attrs: {
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.cancelModal
                                                          }
                                                        },
                                                        [_vm._v("Cancel")]
                                                      ),
                                                      _c(
                                                        "CButton",
                                                        {
                                                          staticClass: "px-4",
                                                          attrs: {
                                                            color: "primary"
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.enterPasswordCode
                                                          }
                                                        },
                                                        [_vm._v("Submit")]
                                                      )
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ])
                                            },
                                            [
                                              !_vm.isCodeReceived
                                                ? _c("CInput", {
                                                    attrs: {
                                                      placeholder:
                                                        "Enter your phone number to recieve code",
                                                      prepend: "+1"
                                                    },
                                                    model: {
                                                      value: _vm.currentEmail,
                                                      callback: function($$v) {
                                                        _vm.currentEmail = $$v
                                                      },
                                                      expression: "currentEmail"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.isCodeReceived
                                                ? _c("CInput", {
                                                    attrs: {
                                                      placeholder: "Enter Code"
                                                    },
                                                    model: {
                                                      value: _vm.code,
                                                      callback: function($$v) {
                                                        _vm.code = $$v
                                                      },
                                                      expression: "code"
                                                    }
                                                  })
                                                : _vm._e(),
                                              _vm.isCodeReceived
                                                ? _c("CInput", {
                                                    attrs: {
                                                      placeholder:
                                                        "Enter new password",
                                                      type: "password"
                                                    },
                                                    model: {
                                                      value: _vm.newPassword,
                                                      callback: function($$v) {
                                                        _vm.newPassword = $$v
                                                      },
                                                      expression: "newPassword"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CCol",
                                        {
                                          staticClass: "text-right",
                                          attrs: { col: "6" }
                                        },
                                        [
                                          _c(
                                            "CButton",
                                            {
                                              staticClass: "px-4",
                                              attrs: { color: "primary" },
                                              on: { click: _vm.login }
                                            },
                                            [_vm._v("Login")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }